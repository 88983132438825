import React from "react";
import styles from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../@_actions/auth.actions";
// import Modal from "../../../@_components/Modal/Modal";
import { NavLink } from "react-router-dom";
// import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';
import TemporaryDrawer from "@_components/Drawer/Drawer";
import LangSelector from "@_components/LangSelector/LangSelector";
import { useTranslation } from "react-i18next";
import { customerActions } from "../../../@_actions/customer.actions.js";

function Header() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);

  const customerDetails = useSelector((state) => state.customerReducer);

  // const caseInfo = useSelector((state) => state.caseDetailsReducer);
  // const payment = useSelector((state) => state.paymentReducer);

  // const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [openModal, setOpenModal] = useState(false);

  function handleLogout() {
    dispatch(authActions.logout());
    dispatch(customerActions.customer(window.location.hostname));
  }

  // function handleOpenModal() {
  //   setOpenModal(true);
  // }

  // function handleCloseModal() {
  //   setOpenModal(false);
  // }
  // function Alert(props) {
  //   return <MuiAlert elevation={6} variant="filled" {...props} />;
  // }
  // const handleClick = () => {
  //   setOpenSnackbar(true);
  // };

  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }

  //   setOpenSnackbar(false);
  // };

  return (
    <>
      {/* <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info">
        Please login to contact us       
        </Alert>
      </Snackbar> */}
      <div className={styles.header}>
        <div className={styles.header_content}>
          <div className={styles.logos}>
            {customerDetails.customer.logo_url ? (
              <div className={styles.logo_box}>
                {customerDetails.customer.homepage ? (
                  <a
                    href={customerDetails.customer.homepage}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.logo_link}
                  >
                    <img
                      src={customerDetails.customer.logo_url}
                      alt={customerDetails.customer.name + " Logo"}
                      className={styles.logo}
                    />
                  </a>
                ) : (
                  <img
                    src={customerDetails.customer.logo_url}
                    alt={customerDetails.customer.name + " Logo"}
                    className={styles.logo}
                  />
                )}
              </div>
            ) : (
              <>
                {customerDetails.customer.homepage ? (
                  <a
                    href={customerDetails.customer.homepage}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className={styles.customer_name_logo}>
                      {customerDetails.customer.name}
                    </p>
                  </a>
                ) : (
                  <p className={styles.customer_name_logo}>
                    {customerDetails.customer.name}
                  </p>
                )}
              </>
            )}
          </div>
          {auth.user ? (
            <>
              <div className={styles.nav_bar}>
                <div className={styles.links}>
                  <NavLink to="caseinfo">
                    <li>{t("_your_case")}</li>
                  </NavLink>
                  {customerDetails.customer.about_us_text && (
                    <NavLink to="about">
                      <li>{t("_about_us")}</li>
                    </NavLink>
                  )}
                  {customerDetails.customer && customerDetails.customer.faq && (
                    <NavLink to="FAQ">
                      <li>{t("_faq")}</li>
                    </NavLink>
                  )}
                  {/* {caseInfo.caseDetails.state !== "ACTIVE" || payment.paymentDetails.state === "PROCESSING" ? (
                    <></>
                    ) : (
                      <div  className={styles.contact_box}>            
                      <li onClick={handleOpenModal}>Contact Us</li>
                    </div>
                )} */}

                  <li onClick={handleLogout} className={styles.logout}>
                    Logout
                  </li>
                  {Object.keys(customerDetails.customer.available_translations)
                    .length > 1 && <LangSelector />}
                </div>
                <div className={styles.langSelectorContainer}>
                  {Object.keys(customerDetails.customer.available_translations)
                    .length > 1 && (
                    <LangSelector className={styles.langSelector} />
                  )}
                </div>
                <div className={styles.drawer}>
                  <TemporaryDrawer />
                </div>
              </div>
            </>
          ) : (
            <div className={styles.nav_bar}>
              <div className={styles.links}>
                {customerDetails.customer.about_us_text && (
                  <NavLink to="about" className={styles.nav_link}>
                    <li>{t("_about_us")}</li>
                  </NavLink>
                )}
                {customerDetails.customer && customerDetails.customer.faq && (
                  <NavLink to="FAQ" className={styles.faq}>
                    <li>{t("_faq")}</li>
                  </NavLink>
                )}
                {/* <div className={styles.contact_box_unauth}>          
         <NavLink to="login"> <li onClick={handleClick}>Login To Contact us</li></NavLink>
          </div> */}
                {Object.keys(customerDetails.customer.available_translations)
                  .length > 1 && <LangSelector />}
              </div>
              <div className={styles.drawer}>
                {Object.keys(customerDetails.customer.available_translations)
                  .length > 1 && <LangSelector />}
                <TemporaryDrawer />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
