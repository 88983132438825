import React, { useEffect, useState } from "react";
import styles from "./ResolveCase.module.css";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal/Modal";
import License from "@_components/popups/License/License";
import Payment from "@_components/popups/Payment/Payment";
import TimerIcon from "@material-ui/icons/Timer";
import { useTranslation } from "react-i18next";
import { invoiceActions } from "@_actions/invoice.actions";
import InfoPopup from "@_components/popups/InfoPopup/InfoPopup";
import Address from "@_components/popups/Address/Address";
import InvoiceList from "@_components/popups/InvoiceList/invoiceList";
import ResolveCaseSummary from "@_components/popups/ResolveCaseSummary/ResolveCaseSummary";
import { caseDetailsActions } from "@_actions/case_details.actions";
import { invoiceConstants } from "@_constants/invoice.constants";
import { billingInfoActions } from "@_actions/billing_info.actions";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import { catchError } from "@_utils/helpers";

function ResolveCase() {
  const { t } = useTranslation();
  const caseInfo = useSelector((state) => state.caseDetailsReducer);
  const invoiceReducer = useSelector((state) => state.invoiceReducer);
  const billingReducer = useSelector((state) => state.billingInfoReducer);
  const payment = useSelector((state) => state.paymentReducer);
  const dispatch = useDispatch();

  const [openLicenseModal, setOpenLicenseModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openInfoPopup, setOpenInfoPopup] = useState(false);
  const [openAddressModal, setOpenAdddressModal] = useState(false);
  const [addressButton, setAddrressButton] = useState("");
  const [addressNext, setAddressNext] = useState("");
  const [openInvoicesPopup, setOpenInvoicesPopup] = useState(false);
  const [openInvoiceAlert, setOpenInvoiceAlert] = useState(false);

  let invoices = caseInfo.caseDetails?.invoices;

  useEffect(() => {
    if (invoiceReducer.status.INVOICE_GENERATE === "success") {
      dispatch(invoiceActions.clearStatusMessage("INVOICE_GENERATE"));
      dispatch(caseDetailsActions.caseDetails());
      handleCloseModal();
    } else if (catchError(invoiceReducer.status.INVOICE_GENERATE) === true) {
      dispatch(invoiceActions.clearStatusMessage("INVOICE_GENERATE"));
      setOpenInvoiceAlert(true);
    }
    // eslint-disable-next-line
  }, [invoiceReducer.status.INVOICE_GENERATE, dispatch]);

  const handleOpenLicenseAlert = () => {
    setOpenInfoPopup(true);
  };

  function handleOpenLicenseModal() {
    setOpenLicenseModal(true);
  }

  const handleOpenAddressModal = (button) => {
    if (button === "generate") {
      setAddrressButton(t("_generate_and_download_button"));
      setAddressNext("generate");
    } else {
      setAddrressButton(t("_proceed_to_payment"));
      setAddressNext("purchase");
    }
    setOpenAdddressModal(true);
    setOpenPaymentModal(false);
    setOpenInvoicesPopup(false);
  };

  function handleOpenPaymentModal() {
    if (payment.message) {
      setOpenPaymentModal(false);
    } else {
      setOpenAdddressModal(false);
      setOpenPaymentModal(true);
      setOpenInvoicesPopup(false);
    }
  }

  const handleDownloadInvoice = () => {
    dispatch(
      invoiceActions.downloadInvoice(invoices[0].id, invoices[0].number)
    );
  };

  function handleCloseModal() {
    dispatch(billingInfoActions.reset());
    setOpenLicenseModal(false);
    setOpenPaymentModal(false);
    setOpenAdddressModal(false);
    setOpenInvoicesPopup(false);
  }

  function handleCloseInvoideAlert() {
    setOpenInvoiceAlert(false);
    handleCloseModal();
  }

  const handleGenerateInvoice = () => {
    dispatch(invoiceActions.generateInvoice());
  };

  const handleResolveCase = () => {
    invoices && invoices[0]?.type === invoiceConstants.ACTIVE_INVOICE
      ? handleOpenPaymentModal()
      : handleOpenAddressModal("purchase");
  };

  const handleClosePaymentModal = () => {
    if (billingReducer.billing_address_saved === true) {
      dispatch(caseDetailsActions.caseDetails());
    }
    handleCloseModal();
  };

  const invoiceButton = (
    <>
      {(!invoices || invoices.length === 0) &&
      (caseInfo.caseDetails.allow_prepayment_invoicing ||
        (caseInfo.caseDetails.state === "PAID" &&
          caseInfo.caseDetails.invoice_template_id !== null)) ? (
        <button
          className={styles.resolve_case_btn}
          onClick={() => handleOpenAddressModal("generate")}
        >
          {t("_generate_invoice_btn")}
        </button>
      ) : invoices?.length === 1 ? (
        <button
          className={styles.resolve_case_btn}
          onClick={handleDownloadInvoice}
        >
          {t("_download_invoice_btn")}
        </button>
      ) : (
        invoices?.length > 1 && (
          <button
            className={styles.resolve_case_btn}
            onClick={() => setOpenInvoicesPopup(true)}
          >
            {t("_invoices")}
          </button>
        )
      )}
    </>
  );
  return (
    <div className={styles.resolve_case_container}>
      {caseInfo.caseDetails.findings ? (
        caseInfo.caseDetails.findings.map((finding) => (
          <div key={finding.id}>
            <Modal show={openLicenseModal} modalClosed={handleCloseModal}>
              <License finding={finding} />
            </Modal>
          </div>
        ))
      ) : (
        <div></div>
      )}

      <InfoPopup
        title={t("_please_upload_your_license_text")}
        open={openInfoPopup}
        close={() => setOpenInfoPopup(false)}
      />

      <Modal
        show={openPaymentModal}
        modalClosed={handleClosePaymentModal}
        disableEnforceFocus="true"
      >
        <Payment onClose={handleClosePaymentModal} />
      </Modal>

      <Modal show={openAddressModal} modalClosed={handleCloseModal}>
        <Address
          onClose={handleCloseModal}
          button={addressButton}
          hideLoading={openInvoiceAlert}
          next={
            addressNext === "generate"
              ? handleGenerateInvoice
              : handleOpenPaymentModal
          }
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openInvoiceAlert}
          autoHideDuration={6000}
          onClose={handleCloseInvoideAlert}
        >
          <Alert onClose={handleCloseInvoideAlert} severity="error">
            {t("_failed_generate_invoice")}
          </Alert>
        </Snackbar>
      </Modal>
      <Modal show={openInvoicesPopup} modalClosed={handleCloseModal}>
        <InvoiceList
          onClose={handleCloseModal}
          caseDetails={caseInfo.caseDetails}
          pay={handleOpenPaymentModal}
          openAddress={() => handleOpenAddressModal("generate")}
        />
      </Modal>

      <ResolveCaseSummary />
      <div className={styles.btn_container}>
        {caseInfo.caseDetails.state === "PAID" && (
          <div className={styles.paid_case}>{invoiceButton}</div>
        )}
        {payment.paymentDetails.state === "PROCESSING" && (
          <div className={styles.paid_case}>
            {" "}
            <TimerIcon fontSize="large" />
          </div>
        )}

        {payment.paymentDetails.state !== "PROCESSING" &&
          caseInfo.caseDetails.state !== "PAID" && (
            <>
              <button
                className={styles.resolve_case_btn}
                onClick={handleResolveCase}
              >
                {t("_pay")}
              </button>
              {invoiceButton}
              {caseInfo.caseDetails.findings &&
              !caseInfo.caseDetails.findings[1] ? (
                <p
                  onClick={handleOpenLicenseModal}
                  className={styles.license_modal_link}
                >
                  {t("_already_own_a_license")}
                </p>
              ) : (
                <p
                  onClick={handleOpenLicenseAlert}
                  className={styles.license_modal_link}
                >
                  {t("_already_own_a_license")}
                </p>
              )}
            </>
          )}
      </div>
    </div>
  );
}

export default ResolveCase;
