import { authConstants } from "@_constants/auth.constants";
import { history, clearToken } from "@_utils/helpers";
import { authService } from "@_services/auth.service";
import UAParser from "ua-parser-js";

export const authActions = {
  login,
  logout,
};

function login(caseNumber, pin, host, user_id) {
  return (dispatch) => {
    dispatch(request());
    const parser = new UAParser();
    const result = parser.getResult();
    const user_device_info = {
      user_agent: result,
      display_size: {
        screen_width: window.screen.width,
        window_width: window.innerWidth,
        screen_height: window.screen.height,
        window_height: window.innerHeight,
        css_style: window.innerWidth > 500 ? "desktop" : "mobile",
      },
    };
    authService.login(caseNumber, pin, host, user_id, user_device_info).then(
      (user) => {
        dispatch(success(user, user_id));
        history.push("/caseinfo");
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: authConstants.LOGIN_REQUEST };
  }
  function success(user, user_id) {
    return { type: authConstants.LOGIN_SUCCESS, user, user_id };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return (dispatch) => {
    clearToken();
    dispatch({ type: authConstants.LOGOUT });
    history.push("/login" + history.location.search);
  };
}
