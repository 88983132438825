import { DialogContent, DialogTitle, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Invoice.module.css";
import { invoiceActions } from "@_actions/invoice.actions";
import { Close, CloudDownload } from "@material-ui/icons";
import { invoiceConstants } from "@_constants/invoice.constants";
import { caseDetailsActions } from "@_actions/case_details.actions";

function InvoiceList(props) {
  const { caseDetails } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const invoiceReducer = useSelector((state) => state.invoiceReducer);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    let invoices = caseDetails?.invoices;
    if (invoices) {
      invoices.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      setInvoices(invoices);
    }
  }, [caseDetails]);

  const handleDownloadInvoice = (invoice) => {
    dispatch(invoiceActions.downloadInvoice(invoice.id, invoice.number));
  };

  useEffect(() => {
    if (invoiceReducer.status.INVOICE_GENERATE === "success") {
      dispatch(caseDetailsActions.caseDetails());
      dispatch(invoiceActions.clearStatusMessage("INVOICE_GENERATE"));
      props.onClose();
    }
    // eslint-disable-next-line
  }, [invoiceReducer.status.INVOICE_GENERATE, dispatch]);

  return (
    <>
      <DialogTitle>
        {t("_invoices")}
        <Close onClick={props.onClose} className={styles.close_icon}></Close>
      </DialogTitle>
      <DialogContent>
        <div>
          {invoices[0]?.type !== invoiceConstants.ACTIVE_INVOICE &&
            (caseDetails?.allow_prepayment_invoicing ||
              caseDetails?.state === "PAID") && (
              <div className={styles.generate_invoice_btn_wrapper}>
                <button
                  onClick={props.openAddress}
                  className={styles.generate_invoice_btn}
                >
                  {t("_generate_invoice_btn")}
                </button>
              </div>
            )}
          {invoices.map((invoice, index) => {
            return (
              <>
                <div className={styles.invoice_row_wide}>
                  <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    className={styles.invoice_row}
                  >
                    <Grid item xs={5} sm={3}>
                      <p className={styles.invoice_date}>
                        {new Date(invoice.created).toLocaleDateString()}
                      </p>
                    </Grid>
                    <Grid item xs={5} sm={5}>
                      <div className={styles.invoice_info}>
                        {invoice.type === invoiceConstants.CANCELLED_INVOICE ? (
                          <span>{t("_cancelled_invoice")}</span>
                        ) : invoice.type ===
                          invoiceConstants.CANCELLATION_INVOICE ? (
                          <span>{t("_cancellation_invoice")}</span>
                        ) : (
                          <span>{t("_invoice")}</span>
                        )}

                        <span>{invoice.number}</span>
                      </div>
                    </Grid>
                    <Grid item xs={2} sm={4}>
                      <div className={styles.invoice_actions}>
                        {index === 0 &&
                          invoice.type === invoiceConstants.ACTIVE_INVOICE &&
                          caseDetails.state !== "PAID" && (
                            <button
                              className={styles.download_invoice_btn}
                              onClick={() => props.pay()}
                            >
                              {t("_pay")}
                            </button>
                          )}
                        <CloudDownload
                          className={styles.download_invoice_icon}
                          onClick={() => handleDownloadInvoice(invoice)}
                        >
                          {t("_download")}
                        </CloudDownload>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.invoice_row_mobile}>
                  <div className={styles.top_row_mobile}>
                    <div className={styles.invoice_info}>
                      <span className={styles.invoice_title_mobile}>
                        {" "}
                        {invoice.type === invoiceConstants.CANCELLED_INVOICE ? (
                          <span>{t("_cancelled_invoice")}</span>
                        ) : invoice.type ===
                          invoiceConstants.CANCELLATION_INVOICE ? (
                          <span>{t("_cancellation_invoice")}</span>
                        ) : (
                          <span>{t("_invoice")}</span>
                        )}
                      </span>

                      <span>{invoice.number}</span>
                      <span className={styles.invoice_date}>
                        {t("_billing_date")}:
                        {new Date(invoice.created).toLocaleDateString()}
                      </span>
                    </div>
                    <CloudDownload
                      className={styles.download_invoice_icon}
                      onClick={() => handleDownloadInvoice(invoice)}
                    >
                      {t("_download")}
                    </CloudDownload>
                  </div>

                  {index === 0 &&
                    invoice.type === invoiceConstants.ACTIVE_INVOICE &&
                    caseDetails.state !== "PAID" && (
                      <button
                        className={styles.pay_mobile_button}
                        onClick={() => props.pay()}
                      >
                        {t("_pay")}
                      </button>
                    )}
                </div>
              </>
            );
          })}
        </div>
      </DialogContent>
    </>
  );
}

export default InvoiceList;
