import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./FindingsItem.module.css";
import classnames from "classnames";
import Modal from "../Modal/Modal";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector, useDispatch } from "react-redux";
import MobileStepper from "@_components/MobileStepper/MobileStepper";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
import PublishIcon from "@material-ui/icons/Publish";
import TimerIcon from "@material-ui/icons/Timer";
import DescriptionIcon from "@material-ui/icons/Description";
import ReceiptIcon from "@material-ui/icons/Receipt";
import License from "@_components/popups/License/License";
import { licenseActions } from "@_actions/license.actions";
import { formatCurrency } from "@_utils/helpers";

import CloseIcon from "@material-ui/icons/Close";
import { caseDetailsActions } from "@_actions/case_details.actions";
let closeImg = {
  position: "absolute",
  cursor: "pointer",
  float: "right",
  width: "20px",
  color: "black",
  right: "10",
  top: "10",
};

function FindingsItem(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openFindingModal, setOpenFindingModal] = useState(false);
  const [openOriginalModal, setOpenOriginalModal] = useState(false);
  const [openLicenseModal, setOpenLicenseModal] = useState(false);
  const [deleteLicense, setDeleteLicense] = useState(false);
  const license = useSelector((state) => state.licenseReducer);

  const payment = useSelector((state) => state.paymentReducer);
  const caseInfo = useSelector((state) => state.caseDetailsReducer);

  let finding = props.finding;

  function handleOpenLicenseModal() {
    setOpenLicenseModal(true);
  }

  function handleCloseLicenseModal() {
    setOpenLicenseModal(false);
  }

  const handleFindingImgModal = (e) => {
    setOpenFindingModal(true);
  };

  const handleOriginalImgModal = (e) => {
    setOpenOriginalModal(true);
  };

  const handleCancelImgModal = (e) => {
    setOpenFindingModal(false);
    setOpenOriginalModal(false);
  };

  const handleDeleteFile = (findingId) => {
    if (finding.license) {
      dispatch(licenseActions.deleteLicense(findingId));
    }
    dispatch(caseDetailsActions.caseDetails());
    setDeleteLicense(true);
  };
  useEffect(() => {
    if (license.status.LICENSE_DELETE === "success") {
      dispatch(caseDetailsActions.caseDetails());
      dispatch(licenseActions.clearStatusMessage("LICENSE_DELETE"));
    }
  });

  let country = caseInfo.caseDetails && caseInfo.caseDetails.country;

  return (
    <div>
      <Modal show={openLicenseModal} modalClosed={handleCloseLicenseModal}>
        <License finding={finding} />
      </Modal>

      {/* no uploaded license */}
      {!finding.license &&
      caseInfo.caseDetails.state === "ACTIVE" &&
      payment.paymentDetails.state !== "PROCESSING" ? (
        <div className={styles.no_license_container}>
          <div className={styles.license_right}>
            <ReceiptIcon className={styles.receipt_icon} />
            <p>{t("_own_a_license_for_this_picture_upload_it")}</p>
          </div>
          <div className={styles.publish_icon_container}>
            <PublishIcon
              className={styles.publish_icon}
              onClick={handleOpenLicenseModal}
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* license pending */}
      {finding.license &&
      finding.license.state === "UNCHECKED" &&
      caseInfo.caseDetails.state === "ACTIVE" &&
      payment.paymentDetails.state !== "PROCESSING" ? (
        <div className={styles.license_pending_container}>
          <TimerIcon className={styles.timer_icon} />
          <p>{t("_review_of_uploaded_license_pending")}</p>
        </div>
      ) : (
        <></>
      )}

      {/* license confirmed */}
      {finding.license && finding.license.state === "VALID" ? (
        <div className={styles.license_confirmed_container}>
          <div className={styles.license_right}>
            <CheckCircleIcon className={styles.check_circle_icon} />
            <p>{t("_uploaded_license_confirmed_text")}</p>
          </div>
          <div>
            <a
              href={finding.license.license_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DescriptionIcon className={styles.description_icon} />
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* license not confirmed  */}
      {finding.license &&
      finding.license.state === "INVALID" &&
      !deleteLicense ? (
        <div className={styles.license_not_confirmed_container}>
          <div className={styles.license_right}>
            <ClearIcon
              className={styles.clear_icon}
              onClick={() => handleDeleteFile(finding.id)}
            />
            <p>{t("_uploaded_license_could_not_be_confirmed")}</p>
          </div>
          <div>
            <a
              href={finding.license.license_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DescriptionIcon className={styles.description_icon} />
            </a>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={styles.findings_container_mobile}>
        <MobileStepper
          finding={finding}
          currency={props.currency}
          country={caseInfo.caseDetails.country}
        />
      </div>

      <div className={styles.findings_container}>
        <Modal show={openFindingModal} modalClosed={handleCancelImgModal}>
          <CloseIcon style={closeImg} onClick={handleCancelImgModal} />
          <div className={styles.modal_image_box}>
            <img
              src={finding.page_screenshot_url}
              className={styles.modal_image}
              alt="finding"
            />
          </div>
        </Modal>
        <Modal show={openOriginalModal} modalClosed={handleCancelImgModal}>
          <CloseIcon style={closeImg} onClick={handleCancelImgModal} />
          <div className={styles.modal_image_box}>
            <img
              src={finding.original_image.original_image_url}
              className={styles.modal_image}
              alt="original"
            />
          </div>
        </Modal>
        <div className={styles.image_col}>
          {caseInfo.loading ? (
            <Skeleton variant="rect" width={294} height={196} />
          ) : (
            <div>
              <div className={styles.image_label}>
                <p>{t("_our_image")}</p>
              </div>
              <div className={styles.image_box}>
                <img
                  src={finding.original_image.original_image_url}
                  alt="original"
                  className={styles.image}
                  onClick={handleOriginalImgModal}
                />
              </div>
            </div>
          )}

          <div className={styles.finding_details}>
            {finding.license && finding.license.state === "VALID" ? (
              <p>{t("_uploaded_license_approved")}</p>
            ) : (
              <p>
                {t("_price")}:{" "}
                {formatCurrency(country, props.currency, props.finding.price)}
              </p>
            )}
            <p>
              {t("_picture_id")}: {props.finding.original_image.filename}
            </p>
          </div>
        </div>
        <div
          className={classnames(
            styles.image_col,
            styles.image_col_finding_image
          )}
        >
          {caseInfo.loading ? (
            <Skeleton variant="rect" width={294} height={196} />
          ) : (
            <div>
              <div className={styles.image_label}>
                <p>{t("_page_screenshot")}</p>
              </div>
              <div className={styles.image_box}>
                <img
                  src={finding.page_screenshot_url}
                  alt="finding"
                  className={styles.image}
                  onClick={handleFindingImgModal}
                />
              </div>
            </div>
          )}
          <p>
            <a
              className={styles.link}
              href={finding.page_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("_page_link")}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default FindingsItem;
