import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { customerActions } from "@_actions/customer.actions.js";

import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import LanguageIcon from "@material-ui/icons/Language";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = withStyles({
  startIcon: {
    marginRight: "11px",
  },
  endIcon: {
    marginLeft: "30px",
  },
  button: {
    width: "175px",
    color: "#3A7BD3",
    textTransform: "none",
    fontSize: "16px",
    boxSizing: "border-box",
    padding: "10px",
  },
  menuPaper: {
    width: "175px",
    border: "1px solid #CCCCCC",
    boxShadow: "none",
    borderRadius: 0,
    padding: "3px",
    boxSizing: "border-box",
  },
  item: {
    fontSize: "16px",
  },
});

function LangSelector(props) {
  const customerDetails = useSelector((state) => state.customerReducer);
  const dispatch = useDispatch();

  const { classes } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const toLangCode = (lang) => {
    if (!lang) return null;

    if (lang.indexOf("-") >= 0) {
      return lang.split("-")[0];
    }

    return lang;
  };

  const langCode =
    toLangCode(customerDetails.customer.translation) ||
    toLangCode(sessionStorage.getItem("i18nextLng"));

  const allLanguageCodes = customerDetails.customer.available_translations;
  const allLanguages = {};

  for (let el of allLanguageCodes) {
    allLanguages[el.language] = el.name;
  }

  const changeLanguage = (langCode) => {
    dispatch(
      customerActions.customer(
        window.location.hostname,
        langCode,
        customerDetails.customer.caseId
      )
    );
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const generateLangItems = () => {
    var list = [];
    for (const langCode in allLanguages) {
      list.push(
        <MenuItem
          className={classes.item}
          onClick={() => changeLanguage(langCode)}
          key={langCode}
        >
          {allLanguages[langCode]}
        </MenuItem>
      );
    }
    return list;
  };

  return (
    <>
      <Button
        className={classes.button}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={
          <LanguageIcon
            classes={{
              iconStyle: classes.largeIcon,
            }}
          />
        }
        endIcon={
          <ExpandMoreIcon
            classes={{
              iconStyle: classes.largeIcon,
            }}
          />
        }
        classes={{
          endIcon: classes.endIcon,
          startIcon: classes.startIcon,
        }}
      >
        {allLanguages[langCode]}
      </Button>
      <Menu
        classes={{ paper: classes.menuPaper }}
        id="menu-appbar"
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {generateLangItems()}
      </Menu>
    </>
  );
}

export default useStyles(LangSelector);
