import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import styles from "./Payment.module.css";
import { paymentActions } from "@_actions/payment.actions";
import Progress from "@_components/Progress/Progress";
import StripePaymentBox from "@_components/StripePaymentBox/StripePaymentBox";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Close } from "@material-ui/icons";

function Payment(props) {
  const dispatch = useDispatch();

  const caseInfo = useSelector((state) => state.caseDetailsReducer);
  const chargeDetails = useSelector((state) => state.chargeReducer);
  const customerDetails = useSelector((state) => state.customerReducer);
  const payment = useSelector((state) => state.paymentReducer);
  const billingReducer = useSelector((state) => state.billingInfoReducer);

  const [billingAddress, setBillingAddressInternal] = useState({
    email: "",
    name: "",
    vat_id: "",
    address_line_1: "",
    address_line_2: "",
    postal_code: "",
    city: "",
    country: "",
  });

  const [lastDisplayedChargeAmount, setLastDisplayedChargeAmount] =
    useState(-1);
  const [stripePaymentIntentSecret, setStripePaymentIntentSecret] =
    useState(undefined);

  const [stripePromise, setStripePromise] = useState(null);

  let charge = chargeDetails.charge;

  useEffect(() => {
    if (
      payment.paymentDetails.state === "SUCCEEDED" ||
      payment.paymentDetails.state === "PROCESSING"
    ) {
      return undefined;
    }
    if (
      charge &&
      charge.settlement_offer &&
      Date.parse(charge.settlement_offer.settlement_date) >= new Date() &&
      payment.paymentDetails.state !== "SUCCEEDED" &&
      payment.paymentDetails.state !== "PROCESSING"
    ) {
      setLastDisplayedChargeAmount(
        parseFloat(charge.final_amount_gross_settlement)
      );
    } else if (
      charge &&
      charge.final_amount_gross_no_settlement &&
      payment.paymentDetails.state !== "SUCCEEDED" &&
      payment.paymentDetails.state !== "PROCESSING"
    ) {
      setLastDisplayedChargeAmount(
        parseFloat(charge.final_amount_gross_no_settlement)
      );
    }
  }, [charge, dispatch, payment.paymentDetails.state]);

  useEffect(() => {
    setStripePaymentIntentSecret(payment.payment.stripe_client_secret);
  }, [
    payment.payment.stripe_client_secret,
    payment.payment.stripe_connected_account_id,
  ]);

  useEffect(() => {
    if (payment.payment.stripe_connected_account_id !== undefined) {
      setStripePromise(
        loadStripe(payment.payment.stripe_public_key, {
          stripeAccount: payment.payment.stripe_connected_account_id,
          locale: customerDetails.customer?.translation || "",
        })
      );
    }
  }, [
    payment.payment.stripe_connected_account_id,
    payment.payment.stripe_public_key,
    customerDetails.customer.translation,
  ]);

  useEffect(() => {
    if (!charge) {
      return <Redirect from="*" to="/caseinfo" />;
    }
    if (caseInfo && caseInfo.caseDetails.billing_address) {
      setBillingAddress({
        email: caseInfo.caseDetails.billing_address.email || "",
        name: caseInfo.caseDetails.billing_address.name || "",
        vat_id: caseInfo.caseDetails.billing_address.vat_id || "",
        address_line_1:
          caseInfo.caseDetails.billing_address.address_line_1 || "",
        address_line_2:
          caseInfo.caseDetails.billing_address.address_line_2 || "",
        postal_code: caseInfo.caseDetails.billing_address.postal_code || "",
        city: caseInfo.caseDetails.billing_address.city || "",
        country: caseInfo.caseDetails.country || "",
      });
    } else if (caseInfo && caseInfo.caseDetails.opponent_contact_details) {
      setBillingAddress({
        email: caseInfo.caseDetails.opponent_contact_details.email || "",
        name: caseInfo.caseDetails.opponent_contact_details.name || "",
        vat_id: "",
        address_line_1:
          caseInfo.caseDetails.opponent_contact_details.address_line_1 || "",
        address_line_2:
          caseInfo.caseDetails.opponent_contact_details.address_line_2 || "",
        postal_code:
          caseInfo.caseDetails.opponent_contact_details.postal_code || "",
        city: caseInfo.caseDetails.opponent_contact_details.city || "",
        country: caseInfo.caseDetails.country || "",
      });
    }
  }, [caseInfo, charge]);

  useEffect(() => {
    if (
      billingReducer.billing_address_saved === true ||
      caseInfo?.caseDetails?.billing_address
    ) {
      if (
        charge &&
        charge.settlement_offer &&
        Date.parse(charge.settlement_offer.settlement_date) >= new Date() &&
        payment.paymentDetails.state !== "SUCCEEDED" &&
        payment.paymentDetails.state !== "PROCESSING"
      ) {
        dispatch(
          paymentActions.payment(
            parseFloat(charge.final_amount_gross_settlement)
          )
        );
      } else if (
        charge &&
        charge.final_amount_gross_no_settlement &&
        payment.paymentDetails.state !== "SUCCEEDED" &&
        payment.paymentDetails.state !== "PROCESSING"
      ) {
        dispatch(
          paymentActions.payment(
            parseFloat(charge.final_amount_gross_no_settlement)
          )
        );
      }
    }
  }, [
    charge,
    dispatch,
    billingReducer.billing_address_saved,
    payment.paymentDetails.state,
    caseInfo.caseDetails.billing_address,
  ]);

  if (!caseInfo) {
    return <Redirect from="*" to="/caseinfo" />;
  }

  if (!charge.currency) {
    return <Redirect from="*" to="/caseinfo" />;
  }

  if (chargeDetails.loading || payment.loading) {
    return <Progress />;
  }

  var options = {
    // passing the client secret
    clientSecret: stripePaymentIntentSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
      variables: { fontSizeBase: "0.9rem" },
    },
  };

  function setBillingAddress(value) {
    setBillingAddressInternal(value);
  }

  const handleClose = () => {
    props.onClose();
  };

  return (
    <div className={styles.payment_modal}>
      <div>
        <Close
          onClick={handleClose}
          style={{ marginTop: 10, float: "right" }}
        ></Close>
      </div>
      <div className={styles.pay_box}>
        {stripePromise !== undefined &&
          stripePaymentIntentSecret !== undefined && (
            <Elements stripe={stripePromise} options={options}>
              <StripePaymentBox
                stripePaymentIntentSecret={stripePaymentIntentSecret}
                finalAmount={lastDisplayedChargeAmount}
                currency={charge.currency}
                country={caseInfo.caseDetails.country}
                billingAddress={billingAddress}
              />
            </Elements>
          )}
      </div>
    </div>
  );
}

export default Payment;
