import { customerConstants } from "@_constants/customer.constants";
import { customerService } from "@_services/customer.service";
import { changeLanguage } from "@_utils/helpers.js";
import UAParser from "ua-parser-js";

export const customerActions = {
  customer,
};

function customer(host, language, caseId, userId, user_device_info) {
  return (dispatch) => {
    dispatch(request());
    language = language || sessionStorage.getItem("i18nextLng");
    language = language && language.split("-")[0];
    caseId = caseId || sessionStorage.getItem("case_id");
    const parser = new UAParser();
    const result = parser.getResult();

    const user_device_info = {
      user_agent: result,
      display_size: {
        screen_width: window.screen.width,
        window_width: window.innerWidth,
        screen_height: window.screen.height,
        window_height: window.innerHeight,
        css_style: window.innerWidth > 500 ? "desktop" : "mobile",
      },
    };

    customerService
      .customer(host, language, caseId, userId, user_device_info)
      .then(
        (data) => {
          data = {
            caseId: caseId,
            ...data,
          };
          changeLanguage(data.translation);
          dispatch(success(data));
        },
        (error) => {
          console.log(error);
          dispatch(failure(error));
        }
      );
  };
}

function request() {
  return { type: customerConstants.CUSTOMER_REQUEST };
}
function success(data) {
  return { type: customerConstants.CUSTOMER_SUCCESS, data };
}
function failure(error) {
  return { type: customerConstants.CUSTOMER_FAILURE, error };
}
