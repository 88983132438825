import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./LicenseConfirmation.module.css";
import { Redirect } from "react-router-dom";
import BackBtn from "@_components/BackBtn/BackBtn";

function LicenseConfirmation() {
  const { t } = useTranslation();
  const license = useSelector((state) => state.licenseReducer);

  if (license.message) {
    return <Redirect to="caseinfo" />;
  }

  return (
    <div>
      <BackBtn />
      <div className={styles.upload_confirmation}>
        <div className={styles.upload_confirmation_header}>
          <h2>
            {t("_case_no")} {sessionStorage.getItem("case_id")}
          </h2>
        </div>
        <div>
          <h2 className={styles.upload_success_text}>
            {t("_upload_successful")}
          </h2>
          <p>
            {t("_we_will_contact_you_after_checking_license_validity_text")}
          </p>
          <p>{t("_thank_you_for_your_cooperation")}</p>
        </div>
      </div>
    </div>
  );
}

export default LicenseConfirmation;
