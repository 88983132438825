import Config from "@_configs/api.config";
import { authHeader } from "../@_utils/helpers";
import { handleResponse } from "@_utils/helpers";

export const billingInfoService = {
  billingInfo,
};

function billingInfo(billingDetails) {
  // replace null values with empty strings, because swagger can't handle them
  let billingDetails_fields = [
    "city",
    "country",
    "email",
    "name",
    "postal_code",
    "street_address",
    "address_line_1",
    "address_line_2",
    "vat_id",
  ];
  for (let field of billingDetails_fields) {
    if (!billingDetails[field]) {
      billingDetails[field] = "";
    }
  }

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(billingDetails),
  };

  return fetch(
    Config.API_ROOT +
      "cases/" +
      sessionStorage.getItem("case_id") +
      "/billing_address",
    requestOptions
  ).then(handleResponse);
}
