import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./CaseInfo.module.css";
import { caseDetailsActions } from "../../@_actions/case_details.actions";
import { chargeActions } from "../../@_actions/charge.actions";
import FindingsItem from "../../@_components/FindingsItem/FindingsItem";
import Progress from "@_components/Progress/Progress";
import ResolveCase from "@_components/ResolveCase/ResolveCase";
import Modal from "../../@_components/Modal/Modal";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { paymentActions } from "@_actions/payment.actions";
import { Redirect } from "react-router-dom";
import { NavLink, useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { invoiceActions } from "@_actions/invoice.actions";
import InvoiceList from "@_components/popups/InvoiceList/invoiceList";

import { customerActions } from "@_actions/customer.actions";
import Address from "@_components/popups/Address/Address";
import { catchError } from "@_utils/helpers";

function CaseInfo() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const caseDetails = useSelector((state) => state.caseDetailsReducer);
  const invoiceReducer = useSelector((state) => state.invoiceReducer);
  const charge = useSelector((state) => state.chargeReducer);
  const payment = useSelector((state) => state.paymentReducer);
  const auth = useSelector((state) => state.authReducer);
  const license = useSelector((state) => state.licenseReducer);
  const customerDetails = useSelector((state) => state.customerReducer);

  const [openModal, setOpenModal] = useState(false);
  const [openInvoicesPopup, setOpenInvoicesPopup] = useState(false);
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [openPaymentAlert, setOpenPaymentAlert] = useState(false);
  const [openLicenseAlert, setOpenLicenseAlert] = useState(false);
  const [openInvoiceAlert, setOpenInvoiceAlert] = useState(false);

  let caseInfo = caseDetails.caseDetails;
  let invoices = caseInfo?.invoices;

  const handleCloseModal = (e) => {
    setOpenModal(false);
    setOpenAddressPopup(false);
    setOpenInvoicesPopup(false);
  };
  function handleCloseInvoideAlert() {
    setOpenInvoiceAlert(false);
    handleCloseModal();
  }
  const handleOpenAddressModal = () => {
    setOpenInvoicesPopup(false);
    setOpenAddressPopup(true);
  };

  useEffect(() => {
    if (invoiceReducer.status.INVOICE_GENERATE === "success") {
      dispatch(invoiceActions.clearStatusMessage("INVOICE_GENERATE"));
      handleCloseModal();
    } else if (catchError(invoiceReducer.status.INVOICE_GENERATE) === true) {
      dispatch(invoiceActions.clearStatusMessage("INVOICE_GENERATE"));
      setOpenInvoiceAlert(true);
    }
    // eslint-disable-next-line
  }, [invoiceReducer.status.INVOICE_GENERATE, dispatch]);

  useEffect(() => {
    dispatch(
      customerActions.customer(
        window.location.hostname,
        null,
        null,
        auth.userId
      )
    );
    // eslint-disable-next-line
  }, [dispatch]);

  const handleClick = () => {
    if (
      payment.message &&
      payment.paymentDetails.state !== "PROCESSING" &&
      caseInfo.caseDetails.state === "ACTIVE"
    ) {
      setOpenPaymentAlert(true);
    }
  };

  const handleDownloadInvoice = () => {
    dispatch(
      invoiceActions.downloadInvoice(invoices[0].id, invoices[0].number)
    );
  };

  const handleGenerateInvoice = () => {
    dispatch(invoiceActions.generateInvoice());
  };

  const handleClosePaymentAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenPaymentAlert(false);
  };

  const handleCloseLicenseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenLicenseAlert(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    if (!auth.user || !sessionStorage.getItem("case_id")) {
      return <Redirect to="/login" />;
    }
  }, [auth.user]);

  useEffect(() => {
    dispatch(caseDetailsActions.caseDetails());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(payment.paymentDetails).length === 0) {
      dispatch(paymentActions.getPayment());
    }
  }, [dispatch, payment.paymentDetails]);

  useEffect(() => {
    dispatch(chargeActions.charge());
  }, [dispatch]);

  useEffect(() => {
    if (
      payment.message &&
      caseInfo.state === "ACTIVE" &&
      payment.paymentDetails.state !== "PROCESSING" &&
      payment.message !==
        "Request denied: this operation is not permitted on paid cases."
    ) {
      setOpenPaymentAlert(true);
    }
  }, [payment, caseInfo.state]);

  useEffect(() => {
    if (license.message) {
      setOpenLicenseAlert(true);
    }
  }, [license.message]);

  if (caseDetails.loading) {
    return <Progress />;
  }

  const invoiceButton = (
    <>
      {" "}
      {(!invoices || invoices.length === 0) &&
      (caseInfo.allow_prepayment_invoicing || caseInfo.state === "PAID") &&
      caseInfo.invoice_template_id ? (
        <NavLink
          to={{ pathname: "/resolve-case", state: "invoice" }}
          className={styles.resolve_case_btn_mobile}
        >
          <button className={styles.resolve_case_btn_mobile}>
            {t("_generate_invoice_btn")}
          </button>
        </NavLink>
      ) : invoices?.length === 1 ? (
        <button
          className={styles.resolve_case_btn_mobile}
          onClick={handleDownloadInvoice}
        >
          {t("_download_invoice_btn")}
        </button>
      ) : (
        invoices?.length > 1 && (
          <button
            className={styles.resolve_case_btn_mobile}
            onClick={() => {
              setOpenInvoicesPopup(true);
            }}
          >
            {t("_invoices")}
          </button>
        )
      )}{" "}
    </>
  );

  return (
    <div className={styles.case_info}>
      <Modal show={openModal} modalClosed={handleCloseModal}>
        {caseInfo.website_contact_details ? (
          <div className={styles.modal_image_box}>
            <img
              src={caseInfo.website_contact_details.screenshot_url}
              className={styles.modal_image}
              alt="Imprint Screenshot"
            />
          </div>
        ) : null}
      </Modal>
      <Snackbar
        open={openPaymentAlert}
        autoHideDuration={6000}
        onClose={handleClosePaymentAlert}
      >
        <Alert onClose={handleClosePaymentAlert} severity="error">
          {t("_failed_prepare_payment_text")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openLicenseAlert}
        autoHideDuration={6000}
        onClose={handleCloseLicenseAlert}
      >
        <Alert onClose={handleCloseLicenseAlert} severity="error">
          {t("_unsupported_file_format")}
        </Alert>
      </Snackbar>
      <Modal show={openAddressPopup} modalClosed={handleCloseModal}>
        <Address
          onClose={handleCloseModal}
          button={t("_generate_and_download_button")}
          hideLoading={openInvoiceAlert}
          next={handleGenerateInvoice}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openInvoiceAlert}
          autoHideDuration={6000}
          onClose={handleCloseInvoideAlert}
        >
          <Alert onClose={handleCloseInvoideAlert} severity="error">
            {t("_failed_generate_invoice")}
          </Alert>
        </Snackbar>
      </Modal>
      <Modal show={openInvoicesPopup} modalClosed={handleCloseModal}>
        <InvoiceList
          onClose={handleCloseModal}
          caseDetails={caseInfo}
          pay={() => history.push("/resolve-case")}
          openAddress={() => handleOpenAddressModal()}
        />
      </Modal>
      <div>
        {/* payment processing */}
        {payment.paymentDetails.state === "PROCESSING" ? (
          <div className={styles.payment_message_container}>
            <div className={styles.payment_message_header}>
              <h2>{t("_payment_processed_text")}</h2>
              <FavoriteIcon className={styles.heart_icon} />
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* payment failed */}
        {payment.paymentDetails.state === "FAILED" ? (
          <div className={styles.payment_failed_container}>
            <div className={styles.payment_failed_header}>
              <h2>{t("_could_not_process_payment")} </h2>
            </div>
            <p>{t("_could_not_process_payment_details")} </p>
          </div>
        ) : (
          <></>
        )}

        {/* payment success */}
        {caseInfo.state === "PAID" ? (
          <div
            className={`${styles.payment_message_container} ${styles.case_closed_text}`}
          >
            <div className={styles.payment_message_header}>
              <h2>{t("_case_closed")}</h2>
              <FavoriteIcon className={styles.heart_icon} />
            </div>
            <p>{t("_thank_you_for_resolving_this_matter_text")}</p>
            <p>{t("_thank_you_for_your_cooperation_and_understanding")}</p>
          </div>
        ) : (
          <></>
        )}

        {/* all valid licenses */}
        {caseInfo.state === "VALID_LICENSE" ? (
          <div className={styles.payment_message_container}>
            <div className={styles.payment_message_header}>
              <h2>{t("_your_case_has_been_resolved")}</h2>
              <FavoriteIcon />
            </div>
            <p>{t("_we_validated_your_license_text")}</p>
            <p>{t("_thank_you_for_your_cooperation_and_understanding")}</p>
          </div>
        ) : (
          <></>
        )}

        <div className={styles.case_info_text_container}>
          <div>
            <h2>
              {t("_case_no")} {sessionStorage.getItem("case_id")}
            </h2>
          </div>
          <div style={{ whiteSpace: "pre-line" }}>
            <p>{customerDetails.customer.case_overview_text}</p>
          </div>
          <div></div>
        </div>
        <div>
          <div className={styles.findings_header}>
            <h3>
              {t("_usages_of_our_pictures_on")} {caseInfo.host}
            </h3>
          </div>
          {caseInfo && caseInfo.findings ? (
            <div>
              {caseInfo.findings.map((finding) => (
                <div key={finding.id}>
                  <FindingsItem
                    finding={finding}
                    currency={charge.charge.currency}
                  />
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {caseInfo.state !== "VALID_LICENSE" && (
        <div className={styles.resolve_case_container}>
          <ResolveCase />
        </div>
      )}
      {caseInfo.state === "ACTIVE" &&
      payment.paymentDetails.state !== "PROCESSING" ? (
        <div className={styles.resolve_case_btn_container_mobile}>
          {payment.message ? (
            <div
              className={styles.resolve_case_btn_container_mobile}
              onClick={handleClick}
            >
              <button className={styles.resolve_case_btn_mobile}>
                {t("_pay")}
              </button>
            </div>
          ) : (
            <div className={styles.resolve_case_btn_container_mobile}>
              <NavLink
                to="/resolve-case-info"
                className={styles.resolve_case_btn_mobile}
              >
                <button className={styles.resolve_case_btn_mobile}>
                  {t("_pay")}
                </button>
              </NavLink>
            </div>
          )}
        </div>
      ) : caseInfo.state === "PAID" &&
        payment.paymentDetails.state === "SUCCEEDED" ? (
        <div className={styles.resolve_case_btn_container_mobile}>
          {invoiceButton}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default CaseInfo;
